import React from 'react'
import Layout from '../components/layout'
import DSG from '../downloads/LEPA-Dienstleistungen-AGB-2015.pdf'

const agb = (props) => {
 

  return (
    <Layout>
      <section className="section-wrapper">
        <header className="section-header">
          <h3 name="title">Allgemeine Geschäftsbedingungen</h3>
        </header>
        <span name="body">
          <p>
          Der Schutz der Rechte und Interessen unserer Kunden ist der OBT Gruppe von höchster Bedeutung. Gegenstand dieser Allgemeinen Geschäftsbedingungen (AGB) sind die Regelungen der Vertragsbeziehung zwischen der LEHMANN+PARTNER Informatik AG und ihren Kunden. Dazu gehören die Rechte und Pflichten beider Parteien sowie die Bedingungen, unter denen unsere Dienstleistungen erbracht werden.
          </p>
          <p>
          Diese AGB richten sich nach den geltenden Bestimmungen des schweizerischen Obligationenrechts (OR) sowie weiteren relevanten gesetzlichen Vorschriften. Sie dienen als Grundlage für eine faire und transparente Zusammenarbeit.
          </p>
          <br />
        </span>
        <div className="download-center">
        <a href= {DSG} target="_blank" rel="noopener noreferrer" className="button-link">Allgemeine Geschäftsbedingungen</a>
        </div>
      </section>
    </Layout>
  )
}

export default agb

